<template>
  <div>
    <dx-util-form ref="userInformationValidationRef"
        :form-data="userRegistrationForm" :show-colon-after-label="true"
        label-location="top" height="560px"
      >
        <dx-util-group-item caption="User Information" :col-count="12">
          <dx-util-item :data-field="'firstName'" :col-span="12" :editor-options="{ inputAttr: { autocomplete: 'chrome-off'}}">
            <dx-util-pattern-rule
                :pattern="namePattern"
                message="Invalid first name (minimum 2 characters/no digits)"
              />
            <dx-util-required-rule message="First name is required" />
          </dx-util-item>
          <dx-util-item :data-field="'middleName'" editor-type="dxTextBox" :col-span="12" :editor-options="{ inputAttr: { autocomplete: 'chrome-off'}}">
          </dx-util-item>
          <dx-util-item :data-field="'lastName'" editor-type="dxTextBox" :col-span="12" :editor-options="{ inputAttr: { autocomplete: 'chrome-off'}}">
            <dx-util-pattern-rule
              :pattern="namePattern"
              message="Invalid last name (minimum 2 characters/no digits)"
            />
            <dx-util-required-rule message="Last name is required" />
          </dx-util-item>
          <dx-util-item :data-field="'loginName'" editor-type="dxTextBox" :col-span="12" :editor-options="{ inputAttr: { autocomplete: 'new-username'}}">
            <dx-util-label text="Username" />
            <dx-util-required-rule message="Username is required" />
            <dx-util-pattern-rule
              :pattern="usernamePattern"
              message="Username consists of alphanumeric characters (a-zA-Z0-9), lowercase, or uppercase.
                      Username allowed of the dot (.), underscore (_), and hyphen (-).
                      The dot (.), underscore (_), or hyphen (-) must not be the first or last character.
                      The dot (.), underscore (_), or hyphen (-) does not appear consecutively, e.g., user..name
                      The number of characters must be between 5 to 20."
            />
          </dx-util-item>
          <dx-util-item :data-field="'email'" editor-type="dxTextBox" :col-span="12" :editor-options="{ inputAttr: { autocomplete: 'new-email'}}">
            <dx-util-email-rule message="Email is invalid" />
            <dx-util-required-rule message="Email is required" />
          </dx-util-item>
          <dx-util-item :data-field="'password'" editor-type="dxTextBox" :editor-options="passwordOptions" :col-span="6">
            <dx-util-required-rule message="Password is required" />
            <dx-util-pattern-rule
                v-if="false"
                :pattern="passwordPattern"
                message="Your password must contain at least one uppercase, one lowercase, one special character and one digit"
              />
          </dx-util-item>
          <dx-util-item :data-field="'passwordCheck'" editor-type="dxTextBox" :editor-options="checkPasswordOptions" :col-span="6">
            <dx-util-required-rule message="Password confirmation is required" />
            <dx-util-compare-rule :comparison-target="comparePassword" message="Password and Confirm Password do not match" />
          </dx-util-item>
          <!-- <dx-util-item template="passwordTemplate" :col-span="12" /> -->
          <dx-util-item template="passwordCheckerTemplate" :col-span="12" />
        </dx-util-group-item>
        <template #passwordCheckerTemplate>
          <div class="">
              <div :class="isLengthValid ? 'text-success': 'text-danger'">
                Minimum length of 8 characters</div>
              <div :class="hasUppercase ? 'text-success' : 'text-danger'">
                At least one uppercase</div>
              <div :class="hasLowercase ? 'text-success' : 'text-danger'">
                At least one lowercase</div>
                <div :class="hasDigit ? 'text-success' : 'text-danger'">
                At least one digit</div>
                <div :class="hasSpecialChar ? 'text-success' : 'text-danger'">
                At least one special character</div>
          </div>
        </template>
        <!-- <template #passwordTemplate>
          <div class="form-group">
            <label for="passwordInput">Password: <span class="text-danger">*</span>  </label>
            <div class="input-group">
              <input id="passwordInput" v-model="userRegistrationForm.password" type="text" class="form-control" @input="checkPasswordRules">
              <div class="input-group-append">
                <div class="input-group-text bg-primary text-white">
                  <i class="icon icon-feather-eye"></i>
                </div>
              </div>
            </div>
          </div>
        </template> -->
      </dx-util-form>
  </div>
</template>

<script>
import {
  computed, ref, // watchEffect, // computed, watch, computed, reactive, onMounted, ref, watchEffect,
} from '@vue/composition-api'
import { useCustomerRegistration } from '../useCustomerRegistration'

export default {
  props: {
    checkForm: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { userRegistrationForm, TenantTypeEnum } = useCustomerRegistration()
    const usernamePattern = /^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){3,18}[a-zA-Z0-9]$/
    const passwordPattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
    const namePattern = /^[a-zA-Z0-9_]{2,}$/

    const hidePasswords = ref(true)
    const password = ref('')

    function changePasswordMode() {
      hidePasswords.value = !hidePasswords.value
    }

    const isLengthValid = ref('')
    const hasDigit = ref('')
    const hasUppercase = ref('')
    const hasLowercase = ref('')
    const hasSpecialChar = ref('')

    function checkPasswordRules(pwd) {
      isLengthValid.value = pwd.length >= 8
      hasDigit.value = /\d/.test(pwd)
      hasUppercase.value = /[A-Z]/.test(pwd)
      hasLowercase.value = /[a-z]/.test(pwd)
      hasSpecialChar.value = /[!@#$%^&*]/.test(pwd)
    }

    const passwordOptions = computed(() => ({
      mode: hidePasswords.value ? 'password' : 'text',
      inputAttr: {
        autocomplete: 'new-password',
      },
      onKeyUp: e => {
        password.value = e.event.target.value
        checkPasswordRules(password.value)
      },
      buttons: [
        {
          name: 'password',
          location: 'after',
          options: {
            icon: hidePasswords.value ? 'icon icon-feather-eye' : 'icon icon-feather-eye-off',
            type: 'default',
            onClick: () => changePasswordMode(),
          },
        },
      ],
    }))

    const checkPasswordOptions = computed(() => ({
      mode: hidePasswords.value ? 'password' : 'text',
      inputAttr: {
        autocomplete: 'new-check-password',
      },
      buttons: [
        {
          name: 'password',
          location: 'after',
          options: {
            icon: hidePasswords.value ? 'icon icon-feather-eye' : 'icon icon-feather-eye-off',
            type: 'default',
            onClick: () => changePasswordMode(),
          },
        },
      ],
    }))

    function comparePassword() {
      return userRegistrationForm.password
    }

    return {
      userRegistrationForm,
      TenantTypeEnum,
      usernamePattern,
      passwordPattern,
      namePattern,
      checkPasswordOptions,
      passwordOptions,
      comparePassword,
      isLengthValid,
      hasDigit,
      hasLowercase,
      hasUppercase,
      hasSpecialChar,
    }
  },
  watch: {
    checkForm(currentValue) {
      if (currentValue) {
        const validationResult = this.$refs.userInformationValidationRef.instance.validate()
        this.$emit('emit-validation-result', validationResult)
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
